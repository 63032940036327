<script setup lang="ts">
import LegalFormSignature from "@/components/LegalFormSignature.vue"
import { useAppConfig, useHttpClient } from "@/vf"
import { computed, ref, watch } from "vue"
import type { ConditionFormData } from "."

interface FormField {
    id: string
    type: string
    text: string
    key: string
    config: any
    choices: string
    section: string
    index: number
}

const props = defineProps<{
    formIds?: string[]
    condition?: string
    formId?: string // html id attribute of the <form> element
    organizations?: { id: string; name: string; label: string }[]
    modelValue?: ConditionFormData
    preProvidedFields?: string[]
    memberAge?: number
    formErrors?: { [key: string]: string[] }
}>()
const emit = defineEmits<{
    (e: "update:modelValue", value: ConditionFormData)
    (e: "formChanged", value: any)
    (e: "submit", value: HTMLFormElement)
    (e: "hasEmptySignatureFields", value: boolean): void
}>()
const formErrors = computed(() => props.formErrors ?? {})
/**
 * Alpha 2 to name map
 */
const countries = ref<Record<string, string>>({})

const form = ref<FormField[]>([])
const http = useHttpClient()
const { appConfig } = useAppConfig()

// make this a 2-way binding
const formData = computed<ConditionFormData>({
    get: () => props.modelValue ?? ({} as ConditionFormData),
    set: value => {
        emit("update:modelValue", value)
    },
})

watch(
    formData.value,
    () => {
        const newStreetAndNumber = ((formData.value.street ?? "") + "\t" + (formData.value.streetNumber ?? "")).trim()
        if (formData.value["street+streetNumber"] != newStreetAndNumber) {
            formData.value["street+streetNumber"] = newStreetAndNumber
        }
        const newZipAndCity = ((formData.value.zip ?? "") + "\t" + (formData.value.city ?? "")).trim()
        if (formData.value["zip+city"] != newZipAndCity) {
            formData.value["zip+city"] = newZipAndCity
        }
        emit("update:modelValue", formData.value)
    },
    { deep: true },
)

watch(
    () => [props.formIds, props.condition],
    async () => {
        if (props.formIds && props.condition) {
            throw Error("You need to specify either formId or condition, not both.")
        }
        if (!props.formIds && !props.condition) {
            throw Error("You need to specify either formId or condition.")
        }
        const params = {}
        if (props.formIds) {
            params["ids"] = props.formIds.join(",")
        }
        if (props.condition) {
            params["required"] = props.condition
        }
        const data = (
            await http.get<{
                forms: any[]
                countries: Record<string, string>
                organizations: { name: string; label: string }[]
            }>("legal/form/", params)
        ).data

        form.value = data.forms ?? []
        countries.value = data.countries
        emit("formChanged", form.value)
        emitEmptyFieldsState()
    },
    { immediate: true },
)

function elementShouldBeVisible(formElement: FormField) {
    // check if the formElement should be visible for the current memberAge
    const isVisibleForMemberByAge = !props.memberAge || formElement.config.visibility !== "u18" || props.memberAge < 18

    // i don't know why this is only for these two types and hadn't time to investigate
    const isSignatureOrInput = formElement.type === "signature" || formElement.type === "input"

    // this is just a guess, but i think we want to hide all fields that are not mapped, because they should already
    // be set or something?
    // const isNotMapped = !formElement.config.mapping

    // check if the field is already provided; this is used to hide specific fields from the form that is already known
    // and must not be prompted again
    const isProvidedField = props.preProvidedFields?.includes(formElement.config.mapping)

    return isSignatureOrInput && isVisibleForMemberByAge && !isProvidedField // && isNotMapped
}

// create a list of all signature fields, so we can iterate them more easily
const signatureFields = computed(() =>
    Object.fromEntries(
        form.value.filter(i => i.type == "signature" && elementShouldBeVisible(i)).map(i => [i.key, { isEmpty: true }]),
    ),
)

// When the "empty" state of a signature field changes, this gets triggered. It will then emit the "hasEmptySignatureFields"
// event to notify the parent element about the change.
function onEmpty(isEmpty: boolean, signatureElement: FormField) {
    signatureFields.value[signatureElement.key] = { isEmpty }
    emitEmptyFieldsState()
}

// For initial state, we assume that all signature fields are empty and inform the parent element about it.
// Once we know which fields this form contains, we update the state. If there are no signature fields, this
// will then change the state back to hasEmptySignatureFields = false. This makes handling the state easier
// for the parent element.
emit("hasEmptySignatureFields", true)

function emitEmptyFieldsState() {
    emit(
        "hasEmptySignatureFields",
        Object.values(signatureFields.value).some((i: any) => i.isEmpty),
    )
}

const sections = computed(() => {
    // sort data.forms by field "section"
    const allFields = [...form.value].sort((a, b) => {
        if (a.section != b.section) {
            a.section.localeCompare(b.section)
        }

        return a.index - b.index
    })

    const sections: Record<string, FormField[]> = {}

    allFields.forEach(field => {
        if (!sections[field.section]) {
            sections[field.section] = []
        }

        sections[field.section].push(field)
    })

    return sections
})
</script>

<template>
    <div v-if="form.length > 0">
        <form method="post" @submit.prevent="ev => emit('submit', ev.target as HTMLFormElement)" :id="props.formId">
            <template v-for="(elements, sectionName) of sections">
                <div class="bg-primary text-white py-2 px-4 font-weight-bold">{{ sectionName }}</div>
                <div class="px-5 mb-5">
                    <div v-for="formElement of elements">
                        <template v-if="elementShouldBeVisible(formElement)">
                            <div class="my-3" v-if="formElement.type === 'input'">
                                <label class="d-block font-weight-bold">{{ formElement.text }}</label>
                                <div class="row" v-if="formElement.config.mapping === 'street+streetNumber'">
                                    <input
                                        type="hidden"
                                        :name="'part[' + formElement.id + '][0]'"
                                        :value="`${formData['street']} ${formData['streetNumber']}`"
                                    />
                                    <div class="col-md-6">
                                        <div class="form-group flex-column" data-type="text">
                                            <div class="flex-grow-1 d-flex">
                                                <label for="street" class="col-form-label required">
                                                    {{ $t("@cms:onlineForm.street") }}
                                                </label>
                                                <div class="flex-grow-1">
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        id="street"
                                                        v-model="formData['street']"
                                                        :required="!!formElement.config.required"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group flex-column" data-type="text">
                                            <div class="flex-grow-1 d-flex">
                                                <label for="streetNumber" class="col-form-label required">
                                                    {{ $t("@cms:onlineForm.streetNumber") }}
                                                </label>
                                                <div class="flex-grow-1">
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        id="streetNumber"
                                                        v-model="formData['streetNumber']"
                                                        :required="!!formElement.config.required"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-else-if="formElement.config.mapping === 'zip+city'">
                                    <input
                                        type="hidden"
                                        :name="'part[' + formElement.id + '][0]'"
                                        :value="`${formData['zip']} ${formData['city']}`"
                                    />
                                    <div class="col-md-6">
                                        <div class="form-group flex-column">
                                            <div class="flex-grow-1 align-items-center d-flex">
                                                <label for="zip" class="col-form-label required">
                                                    {{ $t("@cms:onlineForm.zip") }}
                                                </label>
                                                <div class="flex-grow-1">
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        id="zip"
                                                        v-model="formData['zip']"
                                                        :required="!!formElement.config.required"
                                                    />
                                                    <div
                                                        class="alert alert-danger mb-1"
                                                        v-if="(formErrors?.zip ?? []).length > 0"
                                                    >
                                                        <div v-for="err in formErrors.zip">{{ err }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group flex-column" data-type="text">
                                            <div class="flex-grow-1 d-flex">
                                                <label for="city" class="col-form-label required">
                                                    {{ $t("@cms:onlineForm.city") }}
                                                </label>
                                                <div class="flex-grow-1">
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        id="city"
                                                        v-model="formData['city']"
                                                        :required="!!formElement.config.required"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-else-if="formElement.config.mapping === 'country'">
                                    <select
                                        v-model="formData['country']"
                                        class="form-control"
                                        :name="'part[' + formElement.id + '][0]'"
                                    >
                                        <option v-for="(name, iso) of countries" :value="iso">
                                            {{ name }}
                                        </option>
                                    </select>
                                </div>

                                <div v-if="formElement.config.mapping == 'organization' && appConfig.account">
                                    <select
                                        v-model="formData['organization']"
                                        class="form-control"
                                        :name="'part[' + formElement.id + '][0]'"
                                    >
                                        <option v-for="organization of props.organizations" :value="organization.name">
                                            {{ organization.label }}
                                        </option>
                                    </select>
                                </div>

                                <div v-else v-for="(line, $index) in formElement.config.lines">
                                    <input
                                        :type="
                                            formElement.config.mapping?.includes('+') ||
                                            formElement.config.mapping === 'country'
                                                ? 'hidden'
                                                : 'text'
                                        "
                                        class="form-control my-1"
                                        :maxlength="line.numChars"
                                        :name="'part[' + formElement.id + '][' + $index + ']'"
                                        v-model="
                                            formData[formElement.config.mapping ?? formElement.text + '_' + $index]
                                        "
                                        :required="!!formElement.config.required"
                                    />
                                    <div
                                        class="alert alert-danger mb-1"
                                        v-if="(formErrors?.[formElement.config.mapping] ?? []).length > 0"
                                    >
                                        <div v-for="err in formErrors?.[formElement.config.mapping]">{{ err }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="my-3" v-if="formElement.type === 'radio'">
                                <label class="d-block font-weight-bold">{{ formElement.text }}</label>

                                <label v-for="line in formElement.choices" class="d-block">
                                    <input
                                        type="radio"
                                        :value="line"
                                        v-model="formData[formElement.config.mapping ?? formElement.text]"
                                        :name="'part[' + formElement.id + ']'"
                                    />
                                    {{ line }}
                                </label>
                            </div>
                            <div class="my-3" v-if="formElement.type === 'checkbox'">
                                <label>
                                    <input
                                        type="checkbox"
                                        :name="'part[' + formElement.id + ']'"
                                        v-model="formData[formElement.text]"
                                        value="1"
                                    />
                                    {{ formElement.text }}
                                </label>
                            </div>
                            <div class="my-3" v-if="formElement.type === 'date'">
                                <label class="d-block font-weight-bold">{{ formElement.text }}</label>
                                <input
                                    type="date"
                                    :name="'part[' + formElement.id + ']'"
                                    v-model="formData[formElement.config.mapping ?? formElement.text]"
                                    class="form-control"
                                />
                            </div>
                            <div class="my-3" v-if="formElement.type === 'signature'">
                                <LegalFormSignature
                                    :form-element="formElement"
                                    @empty="isEmpty => onEmpty(isEmpty, formElement)"
                                />
                            </div>
                        </template>
                    </div>
                </div>
            </template>
        </form>
    </div>
</template>

<!--suppress CssUnusedSymbol -->
<style scoped>
.onlineform-text-content span {
    background-color: transparent !important;
}

.signature-container {
    display: grid;
}

.signature-container .signature-pad {
    grid-column: 2 / span 1;
    grid-row: 1;
}

.signature-container .signature-label-city {
    border-top: 1px solid #333;
    grid-column: 1 / span 1;
    grid-row: 2;
}

.signature-container .signature-label-signature {
    border-top: 1px solid #333;
    grid-column: 2 / span 1;
    grid-row: 2;
}
</style>
