<script lang="ts" setup>
import { useHttpClient } from "@/vf"
import { computed } from "vue"

const props = withDefaults(
    defineProps<{
        // exactly one of these three props must be provided:
        conditionId?: string
        versionId?: string
        configuredCondition?: string // e.g. General.User

        /** A scaling factor for the text font size */
        textFactor?: number

        /** When set to true, only the left side of the condition is shown */
        singleLanguage?: boolean

        /**
         * Replace the given keys with the given values in all condition parts.
         * The key must not contain curly braces and should be the same as the placeholder in the condition, that
         * are also used to generate the PDF.
         */
        placeholders?: Record<string, string>
    }>(),
    {
        singleLanguage: false,
    },
)

interface CmsConditionResponse {
    parts: {
        id: string
        type: "title" | "text" | "box" | "space" | "input" | "radio" | "checkbox" | "signature" | "date"
        title?: string
        html?: string
        left?: {
            title: string
            html: string
        }
        right?: {
            title: string
            html: string
        }
        space: number
        size: number
        config: any
    }[]
    name?: string
    left?: {
        name: string
    }
    right?: {
        name: string
    }
    version: {
        date: string
        number: string
    }
}

const path = props.conditionId
    ? "legal/cms/id/" + props.conditionId + (props.singleLanguage ? "/localized" : "")
    : props.versionId
    ? "legal/cms/version/" + props.versionId
    : "legal/cms/condition/" + props.configuredCondition

const data = (await useHttpClient().get<CmsConditionResponse>(path)).data
const textFactor = computed(() => (!props.textFactor ? 1 : props.textFactor))

function handlePlaceholders(html: string) {
    if (!props.placeholders) {
        return html
    }
    for (const key in props.placeholders) {
        const replacement = props.placeholders[key] ?? ""

        html = html.replace(new RegExp(`{${key}}`, "g"), replacement)
    }
    return html
}
</script>

<template>
    <div class="d-flex justify-content-between mb-3" v-if="!singleLanguage">
        <div>
            {{ data.left.name }}
            <br />
            {{ data.version.date }} / Version {{ data.version.number }}
        </div>
        <div class="text-right">
            {{ data.right.name }}
            <br />
            {{ data.version.date }} / Version {{ data.version.number }}
        </div>
    </div>
    <div v-else>
        <div>
            {{ data.name }}
            <br />
            {{ data.version.date }} / Version {{ data.version.number }}
        </div>
    </div>
    <template v-for="part of data.parts">
        <div class="d-flex justify-content-between text-justify" style="gap: 3rem" v-if="!singleLanguage">
            <div style="flex-basis: 50%" v-for="side of ['left', 'right']">
                <h2 v-if="part.type == 'title'" :style="{ fontSize: part.size * textFactor + 'px' }">
                    {{ part[side].title }}
                </h2>
                <div v-if="part.type == 'text'" :style="{ fontSize: part.size * textFactor + 'px' }">
                    <div v-for="html of part[side].html" v-html="handlePlaceholders(html)"></div>
                </div>
                <div v-if="part.type == 'box'" class="border-dark" :style="{ fontSize: part.size * textFactor + 'px' }">
                    <div v-for="html of part[side].html" v-html="handlePlaceholders(html)"></div>
                </div>
                <div v-if="part.type == 'space'" :style="{ height: part.space * 2 + 'px' }"></div>
                <!--                <div v-if="['input', 'radio', 'checkbox', 'date', 'signature'].includes(part.type)">-->
                <!--                    <label-->
                <!--                        class="font-weight-bold"-->
                <!--                        v-for="html of part[side].html"-->
                <!--                        v-html="html"-->
                <!--                        :for="part.id"-->
                <!--                    ></label>-->
                <!--                </div>-->
            </div>
        </div>
        <div v-else>
            <h2 v-if="part.type == 'title'" :style="{ fontSize: part.size * textFactor + 'px' }">
                {{ part.title }}
            </h2>
            <div v-if="part.type == 'text'" :style="{ fontSize: part.size * textFactor + 'px' }">
                <div v-for="html of part.html" v-html="handlePlaceholders(html)"></div>
            </div>
            <div v-if="part.type == 'box'" class="border-dark" :style="{ fontSize: part.size * textFactor + 'px' }">
                <div v-for="html of part.html" v-html="handlePlaceholders(html)"></div>
            </div>
            <div v-if="part.type == 'space'" :style="{ height: part.space * 2 + 'px' }"></div>
        </div>
    </template>
</template>

<style scoped>
:deep(p) {
    margin-bottom: 0;
}
</style>
